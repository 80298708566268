import { OktaAuth } from '@okta/okta-auth-js';

export class OktaAuthService {
  private static instance: OktaAuthService | null = null;
  sdk: any;

  constructor(clientId: string, issuer: string) {
    this.sdk = new OktaAuth({
      clientId,
      issuer,
      redirectUri: `${window.location.origin}/login/oauth2`,
      postLogoutRedirectUri: `${window.location.origin}/logout`
    });
  }

  authGuard = async () => {
    const authenticated = await this.sdk.isAuthenticated({ onExpiredToken: 'none' });
    return new Promise<void>(resolve => {
      if (authenticated) {
        resolve();
      } else {
        this.sdk.setOriginalUri(window.location.pathname);
        this.sdk.signInWithRedirect({
          scopes: ['openid', 'email', 'profile', 'console', 'connectedsearch.query', 'connectedservice.query', 'machinelearning.model', 'machinelearning.predict']
        }).then(resolve);
      }
    });
  };

  getTokens = async (forceAuth = false) => {
    await this._forceAuth(forceAuth);
    return this.sdk.tokenManager.getTokens();
  };

  getUserInfo = async (forceAuth = false) => {
    await this._forceAuth(forceAuth);
    return this.sdk.token.getUserInfo();
  };

  logout = async () => {
    return this.sdk.signOut();
  };

  _forceAuth = async (forceAuth: boolean) => {
    if (forceAuth) {
      await this.authGuard();
    }
  }

  static getInstance(clientId: string, issuer: string): OktaAuthService {
    if (!OktaAuthService.instance) {
      OktaAuthService.instance = new OktaAuthService(clientId, issuer);
    }
    return OktaAuthService.instance;
  }
}
